.fixed-header {
  transition: opacity 0.3s ease;
}

.fixed-header-in {
  opacity: 1;
}

.fixed-header-out {
  opacity: 0;
}